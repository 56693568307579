export default {
  CATALOG_PRODUCT_INVALID_ID: 1,
  CATALOG_PRODUCT_INVALID_SLUG: 2,
  CATALOG_PRODUCT_INVALID_SERIAL_NUMBER: 22,
  CATALOG_PRODUCT_SERIAL_NUMBER_CHECK_ISSUE: 30,
  CATALOG_PRODUCT_NOT_ORDERABLE: 3,
  CATALOG_PRODUCT_ORDERABLE: 4,
  CATALOG_PRODUCT_CART_REMOVE_WITH_ID_NOT_IN_LIST: 5,
  CATALOG_PRODUCT_CART_REMOVE_WITH_OUT_OF_REACH_QUANTITY: 6,
  CATALOG_PRODUCT_FAVORITE_ADD_EXISTS_ID: 28,
  CATALOG_PRODUCT_FAVORITE_REMOVE_WITH_ID_NOT_IN_LIST: 29,
  CATALOG_PRODUCT_COMPARISON_ADD_DUPLICATE: 7,
  CATALOG_PRODUCT_COMPARISON_REMOVE_WITH_ID_NOT_IN_LIST: 8,
  CATALOG_PRODUCT_REVIEW_ADD_WITHOUT_RATING: 21,
  PURCHASE_ORDER_INVALID_ID: 9,
  PURCHASE_ORDER_PAYMENT_TYPE_WRONG: 10,
  PURCHASE_ORDER_CHECKOUT_NO_CART_PRODUCTS: 11,
  PURCHASE_ORDER_INVALID_PAYMENT: 14,
  PURCHASE_ORDER_CHECKOUT_WRONG_PAYMENT: 15,
  PURCHASE_ORDER_INVALID_DELIVERY: 16,
  PURCHASE_COUPON_INVALID_CODE: 17,
  PURCHASE_COUPON_ALREADY_EXISTS: 18,
  PURCHASE_COUPON_REDEEM_COUNT_EXCEEDED: 19,
  PURCHASE_COUPON_EXPIRED: 20,
  ACCOUNT_EMAIL_ALREADY_EXISTS: 23,
  ACCOUNT_PHONE_ALREADY_EXISTS: 24,
  ACCOUNT_USERNAME_INVALID: 25,
  ACCOUNT_PASSWORD_INVALID: 26,
  ACCOUNT_PASSWORD_RESET_INVALID_TOKEN: 27,
};
